<template>
  <div>
    <!-- start of animation, row with shimmer animation -->
    <b-row
      v-if="loading"
      class="d-flex flex-row align-items-center row-container"
    >
      <div class="icon-container" />
      <skeleton-box width="38px" height="38px" />
      <b-col id="payment-info">
        <skeleton-box />
        <skeleton-box />
      </b-col>
    </b-row>
    <!-- end of animation -->
    <b-row
      v-else
      class="d-flex flex-row align-items-center row-container"
      @click="onClick"
    >
      <div class="icon-container">
        <slot name="icon-left"></slot>
      </div>
      <amex-icon v-if="icon === 'american_express'" id="image" />
      <mc-icon v-else-if="icon === 'mc' || icon === 'mastercard'" id="image" />
      <visa-icon v-else-if="icon === 'visa'" id="image" />
      <img
        v-else-if="icon === 'wallet'"
        id="image"
        src="@/assets/img/zubutwallet.png"
      />
      <b-col id="payment-info">
        <p class="noselect">
          {{ title }} <br />
          <span id="subtitle">{{ subtitle }}</span>
        </p>
      </b-col>
      <div>
        <slot name="action"></slot>
      </div>
      <div>
        <slot name="icon-right"></slot>
      </div>
    </b-row>
  </div>
</template>

<script>
import SkeletonBox from "@zubut/common/src/components/SkeletonBox";
import AmexIcon from "@/assets/icons/ic_amex.svg";
import McIcon from "@/assets/icons/ic_mastercard.svg";
import VisaIcon from "@/assets/icons/ic_visa.svg";

export default {
  name: "ZPaymentItem",

  components: {
    AmexIcon,
    McIcon,
    VisaIcon,
    SkeletonBox
  },

  props: {
    onClick: {
      type: Function,
      default: function() {}
    },
    icon: {
      type: String,
      validator: val =>
        ["american_express", "mastercard", "visa", "wallet", "mc"].indexOf(
          val
        ) !== -1,
      default: "wallet"
    },
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
#payment-info {
  min-width: 95px;
}
#image {
  width: 38px;
  height: 38px;
}

p {
  margin: 0;
  font-size: 14px;
  font-weight: 600;

  #subtitle {
    color: $suva-grey;
    font-weight: 350;
  }
}
.row-container {
  margin: 0 !important;
}

.icon-container {
  display: flex;
  justify-content: center;
  width: 30px;
}
</style>
