<template>
  <b-modal
    :show="show"
    :title="modalTitle"
    :visible="show"
    button-size="lg"
    centered
    header-class="modal-header"
    hide-footer
    @hidden="hide"
  >
    <b-container fluid>
      <quick-message
        :message="quickMessage ? quickMessage.message : ''"
        :show="showQuickMessage"
        :type="quickMessage ? quickMessage.type : 'success'"
        with-icon
        class="mb-2"
      />
      <div class="mb-3">
        <p class="font-weight-bold mb-0 mt-3">
          {{ client.name }}
        </p>
        <p class="text-secondary">{{ date }}</p>
      </div>
      <div class="mb-3 payment-details">
        <div v-for="item in paymentBreakdownList" :key="item.name">
          <b-row cols="2">
            <b-col>
              <p :class="item.nameClass">
                {{ item.name }}
              </p>
            </b-col>
            <b-col>
              <p class="text-right" :class="item.valueClass">
                {{ item.value }}
              </p>
            </b-col>
          </b-row>
        </div>
      </div>
      <hr class="mb-4" />
      <div>
        <p class="font-weight-bold mb-0">Total a pagar</p>
        <div class="border mb-3 pl-3 py-2">
          {{ paymentDetailTotal }}
        </div>
      </div>
      <label class="border mb-3 py-2 px-3 w-100" role="button">
        <z-payment-item
          :icon="defPaymentIcon(wallet)"
          :subtitle="defPaymentSubtitle(wallet)"
          :title="defPaymentTitle(wallet)"
        >
          <template v-slot:icon-left>
            <b-form-checkbox
              v-model="selectedPaymentMethodsIds"
              :value="wallet.id"
              class="checkbox"
              size="lg"
              @change="setSelectedPaymentMethod(wallet)"
            />
          </template>
        </z-payment-item>
      </label>
      <div v-if="showCreditCardsAsPaymentOption" class="credit-cards-dropdown">
        <div
          v-for="creditCard in creditCards"
          :key="creditCard.id"
          class="border d-flex mb-3 pl-3 py-2 w-100"
        >
          <label class="w-100 mb-0" role="button">
            <z-payment-item
              :icon="defPaymentIcon(creditCard)"
              :subtitle="defPaymentSubtitle(creditCard)"
              :title="defPaymentTitle(creditCard)"
            >
              <template v-slot:icon-left>
                <b-form-checkbox
                  v-model="selectedPaymentMethodsIds"
                  :value="creditCard.id"
                  class="checkbox"
                  size="lg"
                  @change="setSelectedPaymentMethod(creditCard)"
                />
              </template>
            </z-payment-item>
          </label>
        </div>
      </div>
      <z-button
        class="mb-3 mt-3 w-100"
        :disabled="selectedPaymentMethodId == null || loading"
        @click="payService"
      >
        Pagar
      </z-button>
    </b-container>
  </b-modal>
</template>

<script>
import { formatISO } from "@zubut/common/src/utils/time";
import { makeShortUUID } from "@/utils/strings";
import { formatMoney } from "@/utils/money";
import ZPaymentItem from "@/app/components/ZPaymentItem.vue";

export default {
  name: "ChargeTransactionModal",

  components: {
    ZPaymentItem
  },

  props: {
    show: Boolean,
    amount: {
      type: Number,
      required: true,
      default: 0
    },
    subtotal: {
      type: Number,
      required: true,
      default: 0
    },
    tax: {
      type: Number,
      required: true,
      default: 0
    },
    service: {
      type: Object,
      default: () => ({
        id: ""
      })
    },
    client: {
      type: Object,
      default: () => ({
        name: "",
        wallet: {
          id: "",
          isWallet: true
        },
        creditCards: []
      })
    }
  },

  data() {
    return {
      quickMessage: null,
      showQuickMessage: false,
      loading: false,
      selectedPaymentMethodsIds: [] // needed for checkboxes, use selectedPaymentMethodId instead
    };
  },

  computed: {
    creditCards() {
      return this.client?.creditCards || [];
    },

    selectedPaymentMethodId() {
      return this.selectedPaymentMethodsIds[0] || null;
    },

    showCreditCardsAsPaymentOption() {
      return this.creditCards.length > 0;
    },

    paymentDetailTotal() {
      return this.formatPaymentDetail(this.amount);
    },

    paymentBreakdownList() {
      return [
        {
          name: "Subtotal",
          value: this.formatPaymentDetail(this.subtotal)
        },
        {
          name: this.$t("tax"),
          value: this.formatPaymentDetail(this.tax)
        },
        {
          name: "Total",
          value: this.paymentDetailTotal,
          nameClass: { "font-weight-bold": true },
          valueClass: { "font-weight-bold": true }
        }
      ];
    },

    modalTitle() {
      return "Servicio " + this.shortServiceID;
    },

    defaultCreditCard() {
      return this.client.creditCards.find(card => card.default) || { id: "" };
    },

    wallet() {
      return { ...this.client.wallet, isWallet: true };
    },

    shortServiceID() {
      return makeShortUUID(this.service?.id ?? "");
    },

    date() {
      return (
        this.service?.createdAt && formatISO(this.service.createdAt, "Pp a")
      );
    }
  },

  watch: {
    quickMessage(newMessage) {
      if (newMessage != null) {
        this.showQuickMessage = true;
      } else {
        this.showQuickMessage = false;
      }
    },

    show(newShow) {
      if (newShow) {
        this.setCreditCardDisplayed(this.defaultCreditCard);
      }
    }
  },

  methods: {
    hide() {
      this.$emit("update:show", false);
      this.resetModal();
    },

    formatPaymentDetail(payment) {
      return `${formatMoney(payment)}  `;
    },

    resetModal() {
      this.quickMessage = null;
      this.selectedPaymentMethodsIds = [];
    },

    setCreditCardDisplayed(creditCard) {
      if (creditCard?.id != null) {
        this.selectedPaymentMethodsIds.push(creditCard.id);
      }
    },

    payService() {
      let data = { id: this.service.id, payment: {} };

      if (this.selectedPaymentMethodId === this.wallet.id) {
        data.payment.walletId = this.selectedPaymentMethodId;
      } else {
        data.payment.creditCardId = this.selectedPaymentMethodId;
      }

      this.loading = true;

      this.$store
        .dispatch("services/payService", data)
        .then(res => {
          this.$emit("update:show", false);
          this.$emit("update-listing");
          this.$emit("update:quickMessage", {
            message: "El servició se cobró éxitosamente",
            type: "success"
          });
        })
        .catch(err => {
          this.quickMessage = {
            message: err.message,
            type: "error"
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },

    setSelectedPaymentMethod(item) {
      if (item == null) {
        this.selectedPaymentMethodsIds = [];
      } else {
        if (this.selectedPaymentMethodsIds.length > 1) {
          this.selectedPaymentMethodsIds.shift();
        }
      }
    },

    defPaymentTitle(item) {
      if (item != null) {
        if (item.isWallet) {
          return "Wallet";
        }
        return `**** ${item.last4}`;
      } else {
        return "***";
      }
    },

    defPaymentSubtitle: function(item) {
      if (item != null) {
        if (item.isWallet) {
          return formatMoney(item.amount / 100) + "  ";
        }
        return `${item.exp_month}/${item.exp_year}`;
      } else {
        return `***`;
      }
    },

    defPaymentIcon(item) {
      if (item && item.brand) {
        return item.brand.toLowerCase();
      } else {
        return "wallet";
      }
    }
  }
};
</script>

<style lang="scss">
.payment-details {
  p {
    margin-bottom: 0;
  }
}

.credit-cards-dropdown {
  max-height: 210px;
  overflow-y: scroll;

  .icon-container {
    align-items: center;
    cursor: pointer;
    display: flex;

    .icon {
      display: block;
      margin: 0 auto;
    }
  }

  .content-container {
    overflow: visible;
    position: relative;

    .list-group {
      background-color: $white;
      border-radius: 2px;
      box-shadow: 0 3px 6px 0 rgba($black, 0.23), 0 3px 6px 0 rgba($black, 0.16);
      max-height: 300px;
      overflow-y: scroll;
      position: absolute;
      width: 100%;
      z-index: 1;
    }

    .list-group-item:hover {
      background-color: $white-smoke;
      cursor: pointer;
    }
  }
}
</style>
